body {
    margin: 0;
}

main {
    margin: 16px;
}

.navbar {
    align-items: center;
    background: rgb(255, 255, 255);
    color: white;
    font-family: Helvetica;
    font-weight: 300;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

@media screen and (max-width: 640px) {
    .navbar {
        position: relative;
    }
  }

.navbar__title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
}

.navbar__item {
    padding: 16px 16px;
    cursor: pointer;
    vertical-align: middle;
    display: flex;
}

.react-datepicker__header,
.react-datepicker__header--has-time-select {
  background-color: #05057c;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: white
}

.react-datepicker__day-name {
    color: white
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
    background-color: #05057c;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #05057c;
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
    font-size: 20px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    background-color: #fafafa;
    color: #000;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 2px 10px;
    margin: 2px;
    border: none;
    font-family: 'Quicksand', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    width: 120px;
    height: 32px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #05057c;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll:focus {
    outline: none;
}

.react-datepicker__month-select, .react-datepicker__year-select:focus {
    outline: none;
}

.react-datepicker__month-select::selection {
    background-color: black;
}